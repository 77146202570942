import type { ContentStore } from "@oneaudi/content-service";

import { isTruthy } from "@oneaudi/falcon-common/client";
import { type ContentFragment } from "@oneaudi/falcon-tools";

export type PersonalizationMemory = Map<
  HTMLElement,
  "HYDRATED" | { content: ContentFragment; mbox: adobe.MboxDecision }
>;

export function createHydrateEventListener(
  contentStore: ContentStore,
  memory: PersonalizationMemory
): EventListener {
  return (event: Event) => {
    const featureApp = event.target;
    if (!featureApp || !(featureApp instanceof HTMLElement)) {
      return;
    }

    console.debug(`[${featureApp.id}] Feature App hydrated`);

    const mapEntry = memory.get(featureApp);
    if (mapEntry && mapEntry !== "HYDRATED") {
      console.debug(`[${featureApp.id}] Content available - personalizing`);
      applyPersonalization(contentStore, featureApp.id, mapEntry);
    } else {
      console.debug(`[${featureApp.id}] Remember hydrated state`);
      memory.set(featureApp, "HYDRATED");
    }
  };
}

export function applyPersonalization(
  contentStore: ContentStore,
  featureAppId: string,
  personalization: {
    content: ContentFragment;
    mbox: adobe.MboxDecision & adobe.PrefetchState;
  }
): void {
  const { content, mbox } = personalization;
  contentStore.setContent(featureAppId, content);

  console.log(`[${featureAppId}] Personalized content applied (${mbox.name})`);

  sendNotification(mbox);
}

export function sendNotification(
  mbox: adobe.MboxDecision & adobe.PrefetchState
): void {
  const notification: adobe.Notification = {
    // very cheap id generation
    id: `${Math.random() * 1_000_000_000}`,
    type: "display",
    timestamp: Date.now(),
    mbox: { name: mbox.name, state: mbox.state },
    tokens: [mbox.options?.[0].eventToken].filter(isTruthy),
  };

  adobe.target.sendNotifications({
    request: {
      notifications: [notification],
    },
  });
}
