import { type DataLayerV2 } from "@oneaudi/audi-tracking-service";

import { removePrivateData } from "./tracking-url-sanitizer";

export function sanitizeUrl(url: string): string {
  return removePrivateData(url);
}

export function getPrimaryConsumerId(dom: Element): string {
  const featureAppElements = Array.from(
    dom.getElementsByTagName("feature-app")
  );

  const primaryConsumerElement = featureAppElements.find(
    (singleElement) => singleElement.getAttribute("primary-consumer") === "true"
  );

  if (!primaryConsumerElement) {
    return "";
  }

  return primaryConsumerElement.getAttribute("id") || "";
}

export function configureTrackingService(datalayer: DataLayerV2): void {
  window.featureServiceConfigs ||= {};
  window.featureServiceConfigs["audi-tracking-service"] = [
    {
      datalayer,
      primaryConsumerId: getPrimaryConsumerId(document.body),
      sanitizeUrl,
    },
  ];
}
