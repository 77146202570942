export function loadScript({
  src,
  type,
  async = !type,
}: {
  src: string;
  type?: string;
  async?: boolean;
}): void {
  const s = document.createElement("script");
  if (src.startsWith("http") || src.startsWith("/")) {
    s.setAttribute("src", src);
  } else {
    s.innerText = src;
  }
  if (type) {
    s.setAttribute("type", type);
  }
  s.async = async;
  document.body.appendChild(s);
}
