export class ResponseError extends Error {
  constructor(
    public message: string,
    public status: number,
    public statusText: string,
    public text: string
  ) {
    super(message);
  }

  public static async from(response: Response): Promise<ResponseError> {
    const text = await response.text();

    throw new ResponseError(
      `status: ${response.status} - ${response.statusText}\n${text}`,
      response.status,
      response.statusText,
      text
    );
  }
}
