import type { ContentStore } from "@oneaudi/content-service";

import { assertNever, ResponseError } from "@oneaudi/falcon-common/client";
import {
  isFalconOfferContent,
  type FalconOfferContent,
} from "@oneaudi/falcon-renderer-core/client";

import { fetchContent } from "../../utils/remote-content";

import {
  applyPersonalization,
  sendNotification,
  type PersonalizationMemory,
} from "./apply-personalization";

/**
 *
 * @returns true if mbox content is formatted correctly and referenced is available and matches the target Feature App
 */
export async function applyMbox(
  mbox: adobe.PrefetchMboxDecision,
  {
    featureApp,
    modelPath: originalModelPath,
    memory,
    contentStore,
  }: {
    featureApp: HTMLElement;
    modelPath: string | undefined;
    memory: PersonalizationMemory;
    contentStore: ContentStore;
  }
): Promise<boolean> {
  // For JSON offers, the different options would just overwrite each other.
  // Additionally it seems like there is never more than 1 option for JSON offers anyway.
  // So we can just use the first one
  const option = mbox.options?.[0];

  // There may also be none if the mbox did not match any activity
  if (!option) {
    return false;
  }

  switch (option.type) {
    // "Default content" was selected in Target => just send the event
    case undefined: {
      console.debug(`[${featureApp.id}] Use default content (${mbox.name})`);

      sendNotification(mbox);

      return true;
    }
    case "json": {
      const contentLocation = getContent(option);

      if (!contentLocation) {
        console.warn(
          `[${featureApp.id}] mbox content not recognized (${mbox.name})`
        );
        return false;
      }

      const { contentPath: offerContentPath, modelPath: offerModelPath } =
        contentLocation;

      if (originalModelPath && originalModelPath !== offerModelPath) {
        console.warn(
          `[${featureApp.id}] Offer content model does not match Feature App: ${offerModelPath} === ${originalModelPath} (${mbox.name})`
        );
        return false;
      }

      try {
        const contentFragment = await fetchContent(offerContentPath, false);

        if (memory.get(featureApp) === "HYDRATED") {
          console.debug(
            `[${featureApp.id}] Already hydrated - personalizing immediately`
          );
          applyPersonalization(contentStore, featureApp.id, {
            content: contentFragment,
            mbox,
          });
        } else {
          console.debug(`[${featureApp.id}] Remember content (${mbox.name})`);
          memory.set(featureApp, {
            mbox,
            content: contentFragment,
          });
        }

        return true;
      } catch (error) {
        if (error instanceof ResponseError && error.status === 404) {
          console.warn(
            `[${featureApp.id}] Content for mbox not found (${offerContentPath}). Check for orphan offers! (${mbox.name})`
          );

          return false;
        }

        throw error;
      }
    }

    case "html":
    case "redirect":
    case "dynamic":
    case "actions":
      console.warn(
        `[${featureApp.id}] ${option.type} offers are not supported. (${mbox.name})`
      );
      return false;
    default:
      assertNever(option);
  }
}

export interface CfGraphQlResponse {
  item?: {
    _path?: string;
    _model?: {
      _path?: string;
    };
  };
}

export function getContent(
  option: adobe.JsonOption
): FalconOfferContent | undefined {
  if (isFalconOfferContent(option.content)) {
    return option.content;
  }

  if (
    "data" in option.content &&
    typeof option.content.data === "object" &&
    option.content.data
  ) {
    const key = Object.keys(option.content.data)[0];
    const graphQlResponse = (
      option.content.data as Record<typeof key, CfGraphQlResponse>
    )[key];

    const {
      _path: contentPath,
      _model: { _path: modelPath } = { _path: undefined },
    } = graphQlResponse.item || {};

    if (contentPath && modelPath) {
      return {
        contentPath,
        modelPath,
      };
    }
  }

  return undefined;
}
