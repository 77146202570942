import type { AEMEnvironment, Content, ContentFragment } from "./content-api";

export function assertNever(arg: never, message?: string): never {
  throw new Error(message ?? `Expected never, got: ${arg}`);
}

export function isTruthy<T>(value?: T): value is T {
  return Boolean(value);
}

export function mapObject<TIn, TOut, TKeyIn extends string>(
  obj: Record<TKeyIn, TIn>,
  transformValue: (value: TIn, key: TKeyIn) => TOut
): Record<TKeyIn, TOut>;
export function mapObject<
  TIn,
  TOut,
  TKeyIn extends string,
  TKeyOut extends string
>(
  obj: Record<TKeyIn, TIn>,
  transformValue: (value: TIn, key: TKeyIn) => TOut,
  transformKey: (key: TKeyIn) => TKeyOut
): Record<TKeyOut, TOut>;
export function mapObject<TIn, TOut>(
  obj: Record<string, TIn>,
  transformValue: (value: TIn, key: string) => TOut,
  transformKey: (key: string) => string = (key) => key
): Record<string, TOut> {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [
      transformKey(key),
      transformValue(value, key),
    ])
  );
}

/**
 * Returns a new future Date
 */
export function addSecondsToDate(date: Date, seconds: number): Date {
  return new Date(date.getTime() + seconds * 1000);
}

export function durationTillNowInSeconds(start: Date): number {
  return (Date.now() - start.getTime()) / 1000;
}

export function isMainDeployment(appName: string): boolean {
  return appName === "oneaudi-falcon";
}

/**
 * Detects whether the parameter is complex AEM {@link Content}.
 *
 * Usually used for narrowing down a field of an unknown Content Fragment. Here is is used to distinguish complex
 * content from primitive field types.
 *
 * @param obj - Item to check
 * @returns Whether the item is a piece of AEM {@link Content}
 */
export function isContent(obj: unknown): obj is Content {
  return Boolean(
    obj &&
      Object.prototype.hasOwnProperty.call(obj, "path") &&
      Object.prototype.hasOwnProperty.call(obj, "created") &&
      Object.prototype.hasOwnProperty.call(obj, "status")
  );
}

/**
 * Detects whether the parameter is a {@link ContentFragment}.
 *
 * Usually used for narrowing down a field of an unknown Content Fragment.
 *
 * @param field - The item to check
 * @returns Whether the parameter is an Content Fragment.
 */
export function isContentFragment(field: unknown): field is ContentFragment {
  return Boolean(
    field && Object.prototype.hasOwnProperty.call(field, "fields")
  );
}

/**
 * Detects whether the parameter is an array of {@link ContentFragment | ContentFragments}.
 *
 * Usually used for narrowing down a field of an unknown Content Fragment.
 *
 * @param field - The item to check
 * @returns Whether the parameter is an Content Fragment array.
 */
export function isContentFragmentArray(
  field: unknown
): field is ContentFragment[] {
  return (
    Array.isArray(field) &&
    (field.length === 0 || (field.length > 0 && isContentFragment(field[0])))
  );
}

export function trimIndexUrls(pageUrl: string): string {
  return pageUrl.replace(/\/index$/, "/");
}

interface CreateFullPathOptions {
  aemEnvironment: AEMEnvironment;
  path: string;
}

type FullPath = string;

/**
 * Creates a full path for the given content path which includes the AEM environment id and service
 */
export function createFullPath({
  aemEnvironment,
  path,
}: CreateFullPathOptions): FullPath {
  return `/${aemEnvironment.id}/${aemEnvironment.service}${path}`;
}

export function getSharedFolderName(env: AEMEnvironment): string {
  return `/shared-${env.id}-${env.service.toString()}`;
}
