export interface FalconOfferContent {
  contentPath: string;
  modelPath: string;
}

export function isFalconOfferContent(
  content: unknown
): content is FalconOfferContent {
  return Boolean(
    content &&
      typeof content === "object" &&
      "contentPath" in content &&
      typeof content.contentPath === "string" &&
      content.contentPath &&
      "modelPath" in content &&
      typeof content.modelPath === "string" &&
      content.modelPath
  );
}
