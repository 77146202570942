export const RENDERER_PARTIAL_DIGITAL_DATA_ID = "RENDERER_PARTIAL_DIGITAL_DATA";

export interface DigitalData {
  page?: DigitalDataPage;
  component?: DigitalDataComponent[];
  event?: DigitalDataEvent[];
  dealer?: DigitalDataDealer[];
  user?: DigitalDataUser[];
  products?: DigitalDataProducts;
}

export interface DigitalDataPage {
  pageInfo?: {
    // The full content path of the page
    // Example: /content/dam/country/no/no/bilmodeller/a6-e-tron/a6-avant-e-tron/index
    contentPath?: string;

    // The full path of the page (CMS path). Should be readable and must be in English.
    // Example: /de/brand/de
    pageID?: string;

    // The title of the page
    // Example: Modelle, Aktionen, Angebote | Audi Deutschland
    pageName?: string;

    // document.location.href
    // Example: http://www.audi.de/
    destinationURL?: string;

    // document.referrer
    // Example: http://www.google.de
    referringURL?: string;

    // system environment
    // Example: staging, production, development
    sysEnv?: string;

    // page hierarchy
    // The CMS path (fallback: window.location.pathname) splitted by "/".
    // Should start with first element which is reachable by the user, not static over the whole website
    // and should not include the language or country.
    // For example on "http://www.audi.de/de/brand/de/neuwagen/a3/a3-sportback.html" the variable would
    // look like the following:
    // ["models", "a3", "a3-sportback"]
    breadCrumbs?: string[];

    // The market of the page
    // Format: <ISO 3166-1 alpha-2 : String (uppercase)>
    market?: string;

    // The language of the page
    // Format: <ISO 639 : String (lowercase)>
    language?: string;
  };

  category?: {
    // Type or template of page
    // Example: homepage, standardPage, productPage, articlePage
    pageType?: string;

    // Type how the page is displayed to the user
    // Use "layer" if the page is displayed in a layer. Otherwise use "default".
    // Example: default, layer
    displayType?: "default" | "layer";
  };

  attributes?: {
    // Application name
    applicationName?: string;

    // Application version
    // Example: 6.5.2.8
    applicationVersion?: string;

    implementer?: number;

    // Website name / section
    site?: string;

    // Format of article
    // Example: article, interview, bericht
    articleFormat?: string;

    // The date when the article was published in a dd.mm.yyyy formata
    // Example: 20.06.2023
    articlePublicationDate?: string;

    // The reading time of the article in the format minute:00, since the time is only available in minutes
    // Example: 01:00
    articleReadingTime?: string;

    // Unique ID of an article
    // Example: asdfjkl32e23b
    articleID?: string;

    // Tags that are flagged with the current article
    // Example: ["technology","digitalization"]
    articleContentTags?: string[];

    // The main tag of the article
    // Example: technology
    articleCategory?: string;

    // The date when the article was last modified
    // Example: 20.06.2023
    articleUpdateDate?: string;

    architecture?: string;
  };
}

export interface DigitalDataComponent {
  componentInfo?: {
    // UniqueID of the component. Use concatenation of .moduleNumber and .subNumber, separated by "-".
    componentID?: string;
    componentName?: string;
  };
  category?: {
    // Name of the related application
    primaryCategory?: string;
  };
  attributes?: {
    // ID of implementation agency
    implementer?: number;

    // Version of component. Use concatenation of creation date in CMS and modification date in CMS, separated by "-".
    version?: string;

    // Number of the module within the page, from top to bottom and left to right.
    moduleNumber?: number;

    // Number of the module within the containing module.
    // Please start counting with 0. In case the module does not contain another module, please also set a 0.
    subNumber?: number;

    // Name / title of the teaser
    teaserTitle?: string;
  };
}

export interface DigitalDataEvent {
  eventInfo: {
    eventAction: string;
    eventName: string;
  };
  attributes: {
    componentName: string;
    label: string;
    currentURL: string;
    targetURL: string;
    clickID: string;
    elementName: string;
    value: string;
    pos: string;
  };
}

export interface DigitalDataDealer {
  attributes?: {
    location: string;
  };
  dealerInfo: {
    dealerID: string;
    dealerName: string;
  };
}

export interface DigitalDataUser {
  segment: { isLoggedIn: boolean | "timeout"; userID: string };
}

export interface DigitalDataProducts {
  configuredVehicle?: DigitalDataConfiguredVehicle[];
}

export interface DigitalDataConfiguredVehicle {
  productInfo?: {
    /**
     * Carline ID of the related model.
     *
     * Only to be set on model related pages:
     * Model Start Page ("DSS"), Shop The Look Page, NextGenConfi and Form Pages (e.g. Contact, Book a Testdrive).
     * @example a3sb;
     */
    productID?: string;
    /**
     * Name of the vehicle (if available)
     *
     * @example A3 Sportback
     */
    productName?: string;
    manufacturer?: "Audi" | string;
  };
  category?: {
    /**
     * Carline Group of the related model.
     *
     * Only to be set on model related pages:
     * Model Start Page ("DSS"), Shop The Look Page, NextGenConfi and Form Pages (e.g. Contact, Book a Testdrive).
     *
     * @example a3
     */
    primaryCategory?: string;
    /**
     * Same value as .productID (carline ID)
     *
     * @example a3sb
     */
    subCategory1?: string;
    productType?: "configured car" | string;
  };
}
