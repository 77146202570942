import type { GfaServiceConfigProviderV1 } from "@volkswagen-onehub/gfa-service-config-provider";

import {
  type AEMEnvironment,
  ResponseError,
} from "@oneaudi/falcon-common/client";
import {
  isContentFragment,
  type Asset,
  type ContentFragment,
} from "@oneaudi/falcon-tools";

export async function fetchContent(
  contentPath: string,
  useManagementApi: AEMEnvironment["useManagementApi"] = "with-affinity"
): Promise<ContentFragment> {
  const serviceConfigProviderData = window.featureServiceConfigs?.[
    "gfa:service-config-provider"
  ]?.[0] as GfaServiceConfigProviderV1 | undefined;
  const serviceConfig = serviceConfigProviderData?.audi?.["falcon-content-api"];

  if (!serviceConfig) {
    throw new Error(
      "Cannot find content service config. Check window.featureServiceConfigs"
    );
  }

  if (useManagementApi) {
    // The affinity param does not work as well as expected
    // Give the management API a moment to become consistent
    await new Promise((resolve) => {
      setTimeout(resolve, 1000);
    });
  }

  const url = new URL(
    `${serviceConfig.origin}${serviceConfig.path}?path=${contentPath}`,
    window.location.origin
  );
  if (useManagementApi) {
    url.searchParams.set("useManagementApi", `${useManagementApi}`);
  }

  console.debug("fetchContent: start", url);
  const response = await fetch(url);

  if (!response.ok) {
    const error = await ResponseError.from(response);
    console.debug("fetchContent: error", error);

    throw error;
  }

  const body = await response.json();
  console.debug("fetchContent: end", body);

  const { data } = body;

  if (!isContentFragment(data)) {
    throw new Error(`${contentPath} is not a Content Fragment`);
  }

  return data;
}

export async function fetchContentFragmentOrAsset(
  /**
   * Type "ContentFragment" only loads the specified CF.
   * Type "Asset" loads the parent and extracts the result from the fields.
   *
   * Type "Asset" works for both assets and CFs.
   */
  type: "ContentFragment" | "Asset",
  contentPath: string,
  location: {
    parent: string;
    fieldName: string;
    index?: number;
  }
): Promise<ContentFragment | Asset | undefined> {
  console.debug(
    "fetchContentFragmentOrAsset: fetch start",
    type,
    contentPath,
    location
  );
  if (type === "ContentFragment") {
    const content = await fetchContent(contentPath);
    console.debug(
      "fetchContentFragmentOrAsset: fetch done",
      JSON.stringify(content, undefined, 2)
    );
    return content;
  }
  const parent = await fetchContent(location.parent);
  console.debug(
    "fetchContentFragmentOrAsset: fetch done",
    JSON.stringify(parent, undefined, 2)
  );
  const field = parent.fields[location.fieldName];

  if (Array.isArray(field) && typeof location.index === "number") {
    return (field as Asset[])[location.index];
  }

  return field as Asset | undefined;
}
