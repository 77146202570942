import type { ContentStore } from "@oneaudi/content-service";

import { chunk } from "@oneaudi/falcon-common/client";
import { type ContentFragment } from "@oneaudi/falcon-tools";

import { applyMbox } from "./apply-mbox";
import {
  createHydrateEventListener,
  type PersonalizationMemory,
} from "./apply-personalization";

export function initializePersonalization(contentStore: ContentStore): void {
  // The personalization is essentially a race between when the Feature Apps get hydrated
  // and when the personalized content becomes available. This is made worse by the tag manager providing the
  // Adobe Target at.js library and lazy loading of Feature Apps further down the page.
  // The personalized content can only be applied to a Feature App, when both things happened (i.e. it is hydrated and
  // the content is available). So we need to remember which ever came first, so that we can apply the personalization
  // immediately when the second occurs. This is what the memory is for.
  const memory: PersonalizationMemory = new Map();

  window.addEventListener(
    "feature-app:render",
    createHydrateEventListener(contentStore, memory)
  );

  const featureApps = Array.from(
    document.querySelectorAll<HTMLElement>("feature-app")
  );

  if (typeof adobe !== "undefined" && adobe.target) {
    personalize(featureApps, contentStore, memory);
  } else {
    document.addEventListener("at-library-loaded", () => {
      personalize(featureApps, contentStore, memory);
    });
  }
}

async function personalize(
  featureApps: HTMLElement[],
  contentStore: ContentStore,
  memory: PersonalizationMemory
): Promise<void> {
  if (!adobe.target) {
    throw new Error("at.js is not loaded");
  }

  const publishUrl = document.querySelector<HTMLMetaElement>(
    "meta[name=publish-url]"
  )?.content;
  const currentUrlMbox = new URL(publishUrl || window.location.href);
  currentUrlMbox.search = "";

  const featureAppMap = featureApps.flatMap((fa) => {
    const { contentPath, model: { path: modelPath } = { path: undefined } } =
      getContentForElement(contentStore, fa) || {};

    if (!contentPath) {
      return [];
    }

    currentUrlMbox.hash = contentPath;

    return {
      featureApp: fa,
      modelPath,
      mboxes: [currentUrlMbox.toString(), contentPath],
    };
  });

  const mboxNames = featureAppMap.flatMap(({ mboxes }) => mboxes);
  // Target only allows requesting up to 50 mboxes at the same time
  // https://collaboration.msi.audi.com/jira/browse/WEBART-11717
  const mboxChunks = chunk(mboxNames, 50);

  const responses = await Promise.all(
    mboxChunks.map((current) =>
      adobe.target!.getOffers({
        request: {
          prefetch: {
            mboxes: current.map((mbox, index) => ({ index, name: mbox })),
          },
        },
      })
    )
  );

  console.debug("Target offers", responses);

  const mboxLookUp = Object.fromEntries(
    responses.flatMap((response, index) => {
      if (!response.prefetch?.mboxes) {
        throw new Error(
          `Target did not return a result for the mboxes in chunk ${index}`
        );
      }

      return response.prefetch.mboxes.map((mbox) => [mbox.name, mbox]);
    })
  );

  await Promise.all(
    featureAppMap.map(async ({ featureApp, mboxes, modelPath }) => {
      let success = false;
      for (const mboxName of mboxes) {
        try {
          // We should wait to see whether the more specific mbox returned content before fetching the next
          // to avoid unnecessarily wasting the users bandwidth.
          // Usually at least one mbox is not filled anyway, so this would return immediately
          // eslint-disable-next-line no-await-in-loop
          success = await applyMbox(mboxLookUp[mboxName], {
            featureApp,
            modelPath,
            memory,
            contentStore,
          });

          if (success) {
            break;
          }
        } catch (error) {
          console.error(
            `[${featureApp.id}] Error applying personalization for mbox ${mboxName}.`,
            error
          );
        }
      }

      if (!success) {
        console.debug(`[${featureApp.id}] No personalized content found`);
      }
    })
  );
}

function getContentForElement(
  contentStore: ContentStore,
  featureApp: HTMLElement
): ContentFragment | undefined {
  const content = contentStore.getContent(featureApp.id);

  if (content) {
    return content;
  }

  const rawContent = featureApp.dataset.content;

  if (!rawContent) {
    return undefined;
  }

  try {
    return JSON.parse(rawContent);
  } catch (error) {
    console.warn(
      `[${featureApp.id}] Feature App does not have valid JSON as content`
    );
    return undefined;
  }
}
