import { isAuthorMode } from "../utils/author";
import { setupContentService } from "../utils/content-service";

import { initializePersonalization } from "./personalization";
import { registerTargetMock } from "./personalization/mock";
import { initializeTracking } from "./tracking";

initializeTracking();

const searchParams = new URLSearchParams(window.location.search);

if (
  searchParams.get("d_safemode") !== "true" &&
  (!isAuthorMode() || searchParams.has("at_preview_token"))
) {
  const contentStore = setupContentService();
  initializePersonalization(contentStore);
}

registerTargetMock();
