import { loadScript } from "../../utils/scripts";

function mockTarget(
  appName?: string,
  property: string = "84f11e63-f11a-e83e-620d-90c7b5bc26b6"
): void {
  const isDevDomain = window.location.hostname.includes(".dev.");

  let origin = `https://${appName || "oneaudi-falcon"}.${
    isDevDomain ? "dev" : "prod"
  }.renderer.one.audi`;

  if (!appName && window.location.hostname === "localhost") {
    origin = "";
  }

  loadScript({
    src: `${origin}/static/mock-at-js/mock-at-js.js?at_property=${property}`,
  });
}

export function registerTargetMock(): void {
  window.mockTarget = mockTarget;
}

declare global {
  interface Window {
    mockTarget: typeof mockTarget;
  }
}
