import { ContentStore } from "@oneaudi/content-service";

export function setupContentService(): ContentStore {
  const contentServiceConfig =
    window.featureServiceConfigs?.["audi-content-service"];

  if (contentServiceConfig && contentServiceConfig[0] instanceof ContentStore) {
    return contentServiceConfig[0];
  }

  const contentStore = new ContentStore();
  window.featureServiceConfigs ||= {};
  window.featureServiceConfigs["audi-content-service"] = [contentStore];

  return contentStore;
}
